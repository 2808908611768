<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
            {{ lista_campos != "" ? "Editar" : "Criar" }} cambio
          </h3>
        </div>
        <div class="card-body">
          <form action="">
            <div class="form-group row">
              <div class="col-md-6">
                <label class="col-md-12">Moeda Padrão:*</label>
                <select
                  class="form-control"
                  v-model="form.finan_moeda_id"
                  name=""
                  id=""
                >
                  <option
                    v-for="(lista, index) in lista_finan_moedas"
                    :key="index"
                    :value="lista.id"
                  >
                    {{ lista.nome }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-3">
                <label class="col-md-12">Moeda Secundaria:*</label>
                <select
                  class="form-control"
                  v-model="form.finan_moeda_venda_id"
                  name=""
                  id=""
                >
                  <option
                    v-for="(lista, index) in lista_finan_moedas"
                    :key="index"
                    :value="lista.id"
                  >
                    {{ lista.nome }}
                  </option>
                </select>
              </div>

              <div class="col-md-4">
                <label class="col-md-12">Compra:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.compra"
                  placeholder="Digite o valor de compra..."
                />
              </div>

              <div class="col-md-1 text-center">
                <label class="col-md-12"></label>
                <h2>X</h2>
              </div>

              <div class="col-md-4">
                <label class="col-md-12">Venda:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.venda"
                  placeholder="Digite o valor de venda..."
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Descrição:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.descricao"
                  placeholder="Digite o nome..."
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: "",
        descricao: "",
        status: 1,
        compra: "",
        venda: "",
        finan_moeda_id: "",
        finan_moeda_venda_id: "",
      },
      verif: false,
    };
  },
  computed: {
    lista_finan_moedas() {
      return this.$store.state.financeiro.lista_finan_moedas;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.financeiro.mensagem_alert;
    },
  },
  created() {
    this.$store.dispatch("financeiro/listar_finan_moeda");
    this.preenxerCampor();
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + `  una grade curricular no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("financeiro/create_finan_cambio", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "cambio",
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("financeiro/update_finan_cambio", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "cambio",
      });
    },
    preenxerCampor() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          descricao: this.lista_campos.descricao,
          status: this.lista_campos.status,
          compra: this.lista_campos.compra,
          venda: this.lista_campos.venda,
          finan_moeda_id: this.lista_campos.finan_moeda_id,
        };
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>